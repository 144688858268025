import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'
import moment from 'moment';


const RoomSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [roomSectionData, setRoomSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let all_hotel_rooms = []
                    let room_data = [];
                    const room_section_data = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-room-section-data/' + company_id + '/' + template_id
                    );
                    const result = await room_section_data.json();

                    if (result && result.room_details.data) {
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (result && result.default_hotel) {
                        for (let i = 0; i < all_hotel_rooms.length; i++) {
                            if (all_hotel_rooms[i].hotel_id === result.default_hotel) {
                                room_data.push(all_hotel_rooms[i]);
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < all_hotel_rooms.length; i++) {
                            if (all_hotel_rooms[i].hotel_id === result.all_hotels_data[0].hotel_id) {
                                room_data.push(all_hotel_rooms[i]);
                            }
                        }
                    }


                    if (room_data && result.room_services) {
                        for (let p = 0; p < room_data.length; p++) {
                            for (let q = 0; q < result.room_services.length; q++) {
                                if (room_data[p].hotel_id === result.room_services[q].hotel_id && room_data[p].room_type_id === result.room_services[q].room_type_id) {
                                    room_data[p].room_services = result.room_services[q].room_services;
                                }
                            }
                        }
                    }

                    setRoomSectionData({ "room_details": room_data, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = roomSectionData && roomSectionData.section_details


    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();

    let carousel_loop = [[]];

    let room_details = roomSectionData && roomSectionData.room_details


    if (room_details) {
        let index = 0
        let second_index = -1
        for (let j = 0; j < room_details.length; j++) {

            second_index++;

            if (j % 3 === 0 && j !== 0) {
                carousel_loop.push([]);
                index++;
                second_index = 0;
            }

            carousel_loop[index][second_index] = room_details[j];
        }
    }


    return (

        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            { roomSectionData && roomSectionData.room_details && roomSectionData.room_details.length > 0 && <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="accommodation-slider-wrap">
                            <div id="accommodation-carousel" className="carousel slide" data-ride="slide" data-interval="false">

                                <div className="carousel-inner accommodation-inner">
                                    {carousel_loop && carousel_loop.map(function (items, i) {
                                        return (
                                            <div className={i === 0 ? "item active" : "item"} key={i}>
                                                <div className="row">
                                                    {items && items.map(function (item, id) {
                                                        let allData = from_date + '|' + to_date + '|' + item.hotel_id + '|' + "" + '|' + "" + '|' + ""
                                                        let encryptData = btoa(allData);
                                                        // let iframe = encryptData
                                                        return (
                                                            <div className="col-md-4 padding-zero" key={id}>
                                                                <img src={config.imageEndpoint + '/' + item.room_image[0]} alt={item.room_type + ' ' + "Image"} title={"Image of an" + " " + item.room_type} />
                                                                <figcaption className="fig-cap-padding">
                                                                    <h3 className="accom-h3">{item.room_type}</h3>
                                                                    <p className="accom-para" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                                    <div className="accom-para">

                                                                        {(item && item.max_people) ? <div><i className="fa fa-users" aria-hidden="true"></i> Max {item.max_people} People</div> : ''}
                                                                        {(item && item.bed_type) ? <div><i className="fa fa-bed" aria-hidden="true"></i> {item.bed_type} Size Bed</div> : ''}

                                                                        {item.room_services && item.room_services.map(function (items, id) {
                                                                            if (id < 4) {
                                                                                return (
                                                                                    <div key={id}>
                                                                                        <i className={items.icon} aria-hidden="true"></i>{items.service_title}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                    {item.rack_price && <div className="accom-price-text"><i className="fa fa-inr" aria-hidden="true"></i>{item.rack_price} /Night + Tax</div>}
                                                                    {/* <Link className="accom-btn"
                                                                        to={{
                                                                            pathname: config.PATH + 'booknow',
                                                                            search: '?' + (iframe),
                                                                        }}
                                                                    >Book Online</Link> */}
                                                                    <a className="accom-btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                                                                </figcaption>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                {/* Navigation controllers */}
                                <a className="left carousel-control-width  carousel-control" href="#accommodation-carousel" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control-width  carousel-control" href="#accommodation-carousel" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>}

        </div>

    );
}

export default RoomSection;