import React, {  useState, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Home from './components/4/Home';
import About from './components/4/About';
import Rooms from './components/4/Rooms';
import Facilities from './components/4/Facilities';
import AboutDigha from './components/4/AboutDigha';
import Restaurant from './components/4/Restaurant';
import Contact from './components/4/Contact';
import Gallery from './components/4/Gallery';
import Store from './global_setting/Store';
import BookNow from './components/4/BookNow'
import config from './config'
import history from './history'
import Offers from './components/4/Offers'
import Blog from './components/4/Blog'
import BlogDetails from './components/4/BlogDetails'
import Events from './components/4/Events'
import EventDetails from './components/4/EventDetails'
import NotFound from './components/4/NotFound'
import Packages from './components/4/Packages';
import PackageDetails from './components/4/PackageDetails'
import Services from './components/4/Services';
import Outlets from './components/4/Outlets';
import Careers from './components/4/Careers'
import ReachUs from './components/4/ReachUs'
import Testimonial from './components/4/Testimonial'
import Promotions from './components/4/Promotions'
import ThemeSwitcher from 'react-css-vars';
import HotelsPage from './components/4/HotelsPage';
import CustomPage from './components/4/CustomPage'


function App(props) {
  const [websiteDetails, setwebsiteDetails] = useState();
  const [themeStyle,setThemeStyle]=useState();
  var company_id_val = props.data.company_id;

  useEffect(() => {
    (async function () {
      try {
        if (company_id_val) {
          var company_id = props.data.company_id
          var template_id = props.data.template_id
          const dynamicRouteData = await fetch(
            config.apiEndpoint + '/wb-frontview/get-frontview-routes-data/' + company_id + '/' + template_id
          );
          const json = await dynamicRouteData.json();
          const result = json.data;

          if (result && result.room_details && result.room_details.data) {
            var all_rooms = { data: {} };
            var all_hotel_rooms = []
            for (var i = 0; i < result.room_details.data.length; i++) {
              for (var j = 0; j < result.room_details.data[i].length; j++) {
                all_hotel_rooms.push(result.room_details.data[i][j]);
              }
            }
            all_rooms.data = all_hotel_rooms;
          }

          setwebsiteDetails({ "hotel_packages": result && result.hotel_packages && result.hotel_packages, "hotel_blogs": result && result.hotel_blogs, "hotel_events": result && result.hotel_events, "room_details": all_rooms, "hotel_menus": result && result.hotel_menu_details,'custom_page':result && result.custom_page });
          setThemeStyle({font :result && result.identity_details.theme_font})

        }
      }
      catch (e) {
        console.error(e);
      }
    })();
  }, [company_id_val])


  return (
    <div>
      <ThemeSwitcher theme={themeStyle}>
      <Store>
        <Router history={history}>
          <Switch>
            <Route exact path={config.PATH + '/'} component={Home} />
            <Route exact path={config.PATH + 'home'} component={Home} />
            <Route exact path={config.PATH + 'about'} component={About} />
            <Route exact path={config.PATH + 'room'} component={Rooms} />
            <Route exact path={config.PATH + 'facilities'} component={Facilities} />
            <Route exact path={config.PATH + 'about_digha'} component={AboutDigha} />
            <Route exact path={config.PATH + 'restaurant'} component={Restaurant} />
            <Route exact path={config.PATH + 'offers'} component={Offers} />
            <Route exact path={config.PATH + 'services'} component={Services} />
            <Route exact path={config.PATH + 'events'} component={Events} />

            <Route exact path={config.PATH + 'careers'} component={Careers} />
            <Route exact path={config.PATH + 'reach'} component={ReachUs} />

            {websiteDetails && websiteDetails.hotel_events && websiteDetails.hotel_events.map(function (items, i) {
              let event_title = items.event_title.replace(/[^a-zA-Z0-9 ]/g, "");
              event_title = event_title.replace(/ +/g, "_");
              event_title = event_title.toLowerCase();
              return (
                <Route exact path={config.PATH + '' + event_title} component={EventDetails} key={i} />
              )
            })}

            <Route exact path={config.PATH + 'blog'} component={Blog} />
            {websiteDetails && websiteDetails.hotel_blogs && websiteDetails.hotel_blogs.map(function (items, i) {
              let blog_title = items.blog_title.replace(/[^a-zA-Z0-9 ]/g, "");
              blog_title = blog_title.replace(/ +/g, "_");
              blog_title = blog_title.toLowerCase();
              return (
                <Route exact path={config.PATH + '' + blog_title} component={BlogDetails} key={i} />
              )
            })}
            <Route exact path={config.PATH + 'packages'} component={Packages} />

            {websiteDetails && websiteDetails.hotel_packages && websiteDetails.hotel_packages.map(function (items, i) {
              let package_title = items.package_title.replace(/[^a-zA-Z0-9 ]/g, "");
              package_title = package_title.replace(/ +/g, "_");
              package_title = package_title.toLowerCase();
              return (
                <Route exact path={config.PATH + '' + package_title} component={PackageDetails} key={i} />
              )
            })}

            <Route exact path={config.PATH + 'contact'} component={Contact} />
            <Route exact path={config.PATH + 'gallery'} component={Gallery} />
            <Route exact path={config.PATH + 'booknow'} component={BookNow} />
            <Route exact path={config.PATH + 'page'} component={Outlets} />
            <Route exact path={config.PATH + 'testimonial'} component={Testimonial} />
            <Route exact path={config.PATH + 'promotions'} component={Promotions} />
            <Route exact path={config.PATH+'facilities'} component={Facilities} />

            {websiteDetails && websiteDetails.hotel_menus && websiteDetails.hotel_menus.map(function (items, i) {
              let nme
              nme = items.hotel_name.replace(/[^a-zA-Z ]/g, "");
              nme = nme.replace(/ +/g, "_");
              nme = nme.replace(",", "");
              nme = nme.toLowerCase();
              return (
                <Route exact path={config.PATH + '' + nme} component={HotelsPage} key={i} />
              )
            })}

              {websiteDetails && websiteDetails.custom_page && websiteDetails.custom_page.map(function (items, i) {
                return (
                  <Route exact path={config.PATH + '' + items.page_url} component={CustomPage} key={i} />
                )
              })}

            {websiteDetails && <Route exact path="*" component={NotFound} />}

          </Switch>
        </Router>
      </Store>
      </ThemeSwitcher>
    </div>
  );
}

export default App;

