import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import moment from 'moment';
import _debounce from 'lodash.debounce'
import { Link } from 'react-router-dom'
import OfferSection from './sections/OfferSection'
import AboutSection from './sections/AboutSection'
import ServiceSection from './sections/ServiceSection'
import PackageSection from './sections/PackageSection';
import GallerySection from './sections/GallerySection';
import TestimonialSection from './sections/TestimonialSection';
import FacebookSection from './sections/FacebookSection';
import BlogSection from './sections/BlogSection';
import EventSection from './sections/EventSection';
import NewsletterSection from './sections/NewsletterSection';
import ContactSection from './sections/ContactSection';
import NearbyPlacesSection from './sections/NearbyPlacesSection';
import FacilitiesSection from './sections/FacilitiesSection';
import PromotionalBannerSection from './sections/PromotionalBannerSection';
import PromotionSection from './sections/PromotionSection';
import AwardsSection from './sections/AwardsSection';
import BookingBenefitsSection from './sections/BookingBenefitsSection';
import CustomFooterSection from './sections/CustomFooterSection';
import CustomSection from './sections/CustomSection';

function Rooms() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [roomPageData, setRoomPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let display_default_footer = true;
                    let all_hotel_rooms = []
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-room-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.room_details.data) {
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (all_hotel_rooms && result.room_services) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.room_services.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.room_services[q].hotel_id && all_hotel_rooms[p].room_type_id === result.room_services[q].room_type_id) {
                                    all_hotel_rooms[p].room_services = result.room_services[q].room_services;
                                }
                            }
                        }
                    }

                    if (result && result.room_page_data && result.room_page_data.page_section) {
                        for (let i = 0; i < result.room_page_data.page_section.length; i++) {
                            if (result.room_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    setRoomPageData({ "room_page_data": result && result.room_page_data, "page_section": result && result.room_page_data && result.room_page_data.page_section, "all_rooms": all_hotel_rooms, "all_hotels": result && result.all_hotels, "display_default_footer": display_default_footer })

                    window.$('.carousel').carousel({
                        interval: 5000
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });


    let video_banner_link = roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner

    return (
        <div>
            {(roomPageData === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}


            {roomPageData && roomPageData.room_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{roomPageData.room_page_data.seo_title}</title>
                    <meta name="description" content={roomPageData.room_page_data.seo_description} />
                </MetaTags>
            }

            {roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.banner && <section>
                <div className="standard-top-banner about-top-bg" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + roomPageData.room_page_data.banner + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">{roomPageData.room_page_data.banner_title}</h1>
                    </div>
                </div>
            </section>}

            {roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + roomPageData.room_page_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + roomPageData.room_page_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {roomPageData && roomPageData.room_page_data && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{roomPageData.room_page_data.page_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: roomPageData.room_page_data.page_description }}></p>
                    </div>
                </div>
            </section>}


            {roomPageData && <RoomList data={roomPageData.all_rooms} hotel_list={roomPageData.all_hotels} section_data={roomPageData.section_details} companyDetails={companyDetails}/>}

            {roomPageData && roomPageData.page_section && roomPageData.page_section.map(function (item, i) {

                if (item.section_names === "Promotional Banner") {
                    return (
                        <PromotionalBannerSection key={i} />
                    )
                }

                if (item.section_names === "Newsletter") {
                    return (
                        <NewsletterSection key={i} />
                    )
                }

                if (item.section_names === "About Us") {
                    return (
                        <AboutSection key={i} />
                    )
                }


                if (item.section_names === "Gallery") {
                    return (
                        <GallerySection key={i} />
                    )
                }

                if (item.section_names === "Contact Us") {
                    return (
                        <ContactSection key={i} />
                    )
                }

                if (item.section_names === "Services") {
                    return (
                        <ServiceSection key={i} />
                    )
                }


                if (item.section_names === "Nearby Places") {
                    return (
                        <NearbyPlacesSection key={i} />
                    )
                }

                if (item.section_names === "Testimonial") {
                    return (
                        <TestimonialSection key={i} />
                    )
                }


                if (item.section_names === "Facility") {
                    return (
                        <FacilitiesSection key={i} />
                    )
                }


                if (item.section_names === "Package") {
                    return (
                        <PackageSection key={i} />
                    )
                }

                if (item.section_names === "Blog") {
                    return (
                        <BlogSection key={i} />
                    )
                }

                if (item.section_names === "Event") {
                    return (
                        <EventSection key={i} />
                    )
                }

                if (item.section_names === "Offer") {
                    return (
                        <OfferSection key={i} />
                    )
                }


                if (item.section_names === "Facebook") {
                    return (
                        <FacebookSection key={i} />
                    )
                }
                if (item.section_names === "Promotions") {
                    return (
                        <PromotionSection key={i} />

                    )
                }

                if (item.section_names === "Awards") {
                    return (
                        <AwardsSection key={i} />
                    )
                }


                if (item.section_names === "Booking Benfits") {
                    return (
                        <BookingBenefitsSection key={i} />
                    )
                }

                if (item.section_type === "custom") {
                    return (
                        <CustomSection key={i} section_id={item.id} name={item.section_names}/>
                    )
                }

                if (item.section_names === "Custom Footer") {
                    return (
                        <CustomFooterSection key={i} />
                    )
                }
            })}

            {roomPageData && roomPageData.display_default_footer && roomPageData.display_default_footer === true && <Footer />}


            <a id="" className="scrollup show" title="Go To Top" style={{ bottom: "15px" }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}></a>

        </div>

    );
}

function RoomList(props) {
    let list_data = props.data;
    let companyDetails = props.companyDetails;
    let hotel_list = props.hotel_list
    let section_data = props.section_data

    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();

    let websitefont = '';

    const [hotelId, setHotelId] = useState(hotel_list && hotel_list[0].hotel_id);
    const hotelName = (e) => {
        setHotelId(parseInt(e));
    }

    return (
        <section>
            <div className="container container-space margin-top">
                <div className="explor_title row m0">
                    {section_data && <div className="pull-left">
                        <div className="left_ex_title">
                            <h2 style={{ fontFamily: websitefont }}>{section_data.section_title}</h2>
                        </div>
                    </div>}
                    {hotel_list && hotel_list.length > 1 &&
                        <div className="pull-right">
                            <div className="page-header text-center text-uppercase">
                                <select className="form-control select-form" onChange={(e) => { hotelName(e.target.value) }}>
                                    {hotel_list && hotel_list.map(function (item, i) {
                                        return (
                                            <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="container">

                {list_data && list_data.map(function (item, id) {
                    if (hotelId === item.hotel_id) {
                        let allData = from_date + '|' + to_date + '|' + item.hotel_id + '|' + "" + '|' + "" + '|' + ""
                        let encryptData = btoa(allData);
                        // let iframe = encryptData
                        return (
                            <div className="row room-margin-top margin-top" key={id}>
                                <div className="col-md-12 col-bg">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="room-img">
                                                <img src={config.imageEndpoint + '/' + item.room_image[0]} alt={item.room_type} title={"Image of an" + " " + item.room_type} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 padding-left">
                                            <h3 className="room-h3">{item.room_type}</h3>
                                            <p className="room-para" dangerouslySetInnerHTML={{ __html: item.description }}></p>


                                            <div className="row">
                                                <div className="col-md-6 col-sm-6">

                                                    {(item && item.max_people) ?
                                                        <div className="room-amenities">
                                                            <i className="fa fa-users" aria-hidden="true"></i>Max {item.max_people} People
                                                </div> : ''}

                                                    {item.room_services && item.room_services.map(function (items, id) {

                                                        if (id % 2 === 0) {
                                                            return (
                                                                <div className="room-amenities" key={id}>
                                                                    <i className={items.icon} aria-hidden="true"></i>{items.service_title}
                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                </div>

                                                <div className="col-md-6 col-sm-6">
                                                    {(item && item.bed_type) ?
                                                        <div className="room-amenities">
                                                            <i className="fa fa-bed" aria-hidden="true"></i>{item.bed_type} Size Bed
                                                </div> : ''}

                                                    {item.room_services && item.room_services.map(function (items, id) {
                                                        if (id % 2 !== 0) {
                                                            return (
                                                                <div className="room-amenities" key={id}>
                                                                    <i className={items.icon} aria-hidden="true"></i>{items.service_title}
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3">
                                            <div className="price-wrap">
                                                {item.rack_price && <div className="room-price">₹ {item.rack_price}</div>}
                                                {item.rack_price && <span className="room-price-label">Starting Rate/Night +Tax</span>}
                                                {/* <Link className="standard-btn room-btn-margin"
                                                    to={{
                                                        pathname: config.PATH + 'booknow',
                                                        search: '?' + (iframe),
                                                    }}
                                                >Book Now</Link> */}
                                                <a className="standard-btn room-btn-margin" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    }
                })}

            </div>
        </section>

    )
}

export default Rooms;