import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'

const EventSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [eventSectionData, setEventSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const eventSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-event-section-data/' + company_id + '/' + template_id
                    );
                    const result = await eventSection.json();

                    setEventSectionData({ "events_data": result && result.hotel_events, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = eventSectionData && eventSectionData.section_details


    return (
        <div>


            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">

                    <div className="row">
                        <div className="package-slider-wrap">
                            <div id="event-page-carousel" className="carousel slide" data-ride="slide" data-interval="false">
                                <div className="carousel-inner">

                                    {eventSectionData && eventSectionData.events_data && eventSectionData.events_data.map(function (item, i) {
                                        var send_title = item.event_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                        send_title = send_title.replace(/ +/g, "_");
                                        send_title = send_title.toLowerCase();
                                        return (
                                            <div className={i === 0 ? "item active" : "item"} key={i}>
                                                <img src={config.wbImagesEndpoint + '/' + item.event_image} alt={item.event_image_alt} />
                                                <figcaption>
                                                    <div className="fig-caption-text">
                                                        <span className="fig-inner-text">{item.event_title}</span>
                                                        <span className="package-btn-link">
                                                            <Link className="enquire-now" tabIndex="-1" to={{
                                                                pathname: config.PATH + '' + send_title

                                                            }} style={{ fontFamily: websitefont }}>
                                                                More Details
                                                                </Link>&nbsp;

                                                        </span>
                                                    </div>
                                                </figcaption>
                                            </div>
                                        )
                                    })}
                                </div>

                                <a className="left carousel-control-width carousel-control" href="#event-page-carousel" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control-width carousel-control" href="#event-page-carousel" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default EventSection;