import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext} from '../../global_setting/Store';
import config from '../../../src/config.json'
import history from '../../history';
import { slide as Menu } from 'react-burger-menu';

function Header() {
    const [companyDetails] = useContext(CompanyContext);

    let route_url=window.location.href

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    var todayDate = new Date();
    var dd = String(todayDate.getDate()).padStart(2, '0');
    var mm = String(todayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = todayDate.getFullYear();
    todayDate = mm + '/' + dd + '/' + yyyy;

    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-header-data/' + company_id + '/' + template_id
                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    

                    if (result && result.header_menu_details.length > 9) {
                        let header_menu = result.header_menu_details;
                        var extra_header_menu = header_menu.splice(8, 10);

                        extra_header_menu.forEach(function (item, i) {
                            item["menu"] = "active";
                        })
                    } else {
                        extra_header_menu = [];
                    }

                    var all_menu_details = {
                        "header": {
                            "header_menus": result && result.header_menu_details,
                            "other_menus": extra_header_menu
                        }
                    }

                    let numbers = [];
                    let emails = [];
                    if (result && result.hotel_details) {
                        numbers = result.hotel_details.original[0].mobile.split(',');
                        emails = result.hotel_details.original[0].email_id.split(',');
                    }

                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "awards": result.awards && result.awards.original, "packages": result.packages_list && result.packages_list.original,"phone_numbers": numbers, "emails": emails });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    useEffect(() => {

        let element1 = document.getElementById("chat-circle");
        let element2 = document.getElementById("initial-msg");
        let element3 = document.getElementById("firstdiv");

        if(element1 && element2 && element3){
            if(route_url.includes('booknow?')){
                element1.style["display"]="none"
                element2.style["display"]="none"
                element3.style["display"]="none"


            }
            else{
                element1.style["display"]=""
                element2.style["display"]=""
                element3.style["display"]="none"

            }     
        } 

    },[route_url]);

    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]

    let url = window.location.href;
    var splitUrl = url.split('/');
    var activemenu = splitUrl[splitUrl.length - 1]
    


    //function use to redirect the page
    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }

    }


    return (
        <div>
   
            <div className="container-nav">
                {/* Sticky Navbar  */}

                <header className="sticky-header">
                    
                    <div className="hidden-md hidden-lg">
                        <Menu>
                            {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                if(item.page_id===1){
                                    item.page=item.page_url;
                                }
                                return (
                                    <a id={item.menu_name} className="menu-item"  onClick={() => redirectTo(item)} key={i}>{item.menu_name}</a>
                                )
                            })}

                            {websiteDetails && websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                return(
                                <a id={item.menu_name} className="menu-item"  onClick={() => redirectTo(item)} key={i}>{item.menu_name}</a>
                            )})}

                    
                        </Menu>
                    </div>
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">

                                {/* Navbar  */}
                                <div className="nav">

                                    {/* Logo  */}

                                    <div className="logo-wrap pull-left logo-center-sm">
                                        {websiteDetails && websiteDetails.template_identity &&
                                            <img src={ config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt}  />
                                            
                                        }

                                        {websiteDetails && websiteDetails.template_identity &&
                                            <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} />
                                        }

                                    </div>

                                    <div className="mobile-social-nav mobile-text-center hidden-md hidden-lg">
                                        <div className="date-contact mobile-date">
                                            <span className="date-box">Date: {todayDate}</span>

                                            {websiteDetails && websiteDetails.phone_numbers.length > 0 && <span className="contact-box">&nbsp;&nbsp;Call Us.
                                            {websiteDetails.phone_numbers.map(function (items, i) {
                                                return (
                                                    <span key={i}> <a className="phone-number" href={"tel:" + items}>{items}</a>{i < websiteDetails.phone_numbers.length - 1 && <span>,</span>}</span>
                                                )
                                            })}
                                            </span>}
                                        </div>

                                        <div className="header-social-links">
                                            <ul className="social-links">
                                            
                                                {(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? <li><a className="mobile-social" href={hotelDetails.facebook_link}> <i className="fa fa-facebook"></i></a></li> : ''}
                                                {(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? <li><a className="mobile-social" href={hotelDetails.twitter_link}> <i className="fa fa-twitter"></i></a></li> : ''}
                                                {(websiteDetails && hotelDetails && hotelDetails.linked_in_link) ? <li><a className="mobile-social" href={hotelDetails.linked_in_link}> <i className="fa fa-linkedin"></i></a></li> : ''}
                                                {(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? <li><a className="mobile-social" href={hotelDetails.instagram_link}> <i className="fa fa-instagram"></i></a></li> : ''}
                                                {(websiteDetails && hotelDetails && hotelDetails.tripadvisor_link) ? <li><a className="mobile-social" href={hotelDetails.tripadvisor_link}> <i className="fa fa-tripadvisor"></i></a></li> : ''}
                                                
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Menu Bar  */}

                                    <div className="nav-menu pull-right hidden-xs">
                                        {/* Top Menu  */}


                                        <div className="top-menu clearfix">
                                            <div className="date-contact">
                                                <span className="date-box">Date: {todayDate}</span>

                                                {websiteDetails && websiteDetails.phone_numbers.length > 0 && <span className="contact-box">&nbsp;&nbsp;Call Us.
                                            {websiteDetails.phone_numbers.map(function (items, i) {
                                                    return (
                                                        <span key={i}> <a className="phone-number" href={"tel:" + items}>{items}</a>{i < websiteDetails.phone_numbers.length - 1 && <span>,</span>}</span>
                                                    )
                                                })}
                                                </span>}

                                            </div>

                                            <div className="header-social-links">
                                                <ul className="social-links">
                                                    {(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? <li><a className="mobile-social" href={hotelDetails.facebook_link}><i className="fa fa-facebook"></i></a></li> : ''}
                                                    {(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? <li><a className="mobile-social" href={hotelDetails.twitter_link}><i className="fa fa-twitter"></i></a></li> : ''}
                                                    {(websiteDetails && hotelDetails && hotelDetails.linked_in_link) ? <li><a className="mobile-social" href={hotelDetails.linked_in_link}><i className="fa fa-linkedin"></i></a></li> : ''}
                                                    {(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? <li><a className="mobile-social" href={hotelDetails.instagram_link}><i className="fa fa-instagram"></i></a></li> : ''}
                                                    {(websiteDetails && hotelDetails && hotelDetails.tripadvisor_link) ? <li><a className="mobile-social" href={hotelDetails.tripadvisor_link}> <i className="fa fa-tripadvisor"></i></a></li> : ''}

                                                </ul>
                                            </div>
                                        </div>

                                        {/* Bottom/Lower Menu  */}


                                        <div className="lower-menu">
                                            <ul className="main-menu">
                                                {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                                    if(item.page_id===1){
                                                        item.page=item.page_url;
                                                    }
                                                  
                                                    return (
                                                        <li key={i}>
                                                            <a className={(item.page === activemenu ? 'nav-link-style link-active' : 'nav-link-style')} onClick={() => redirectTo(item)}>{item.menu_name}</a>
                                                        </li>
                                                    )
                                                })}
                                                                                             
                                               
                                                {websiteDetails && websiteDetails.menu_details
                                                    && websiteDetails.menu_details.header.other_menus.length > 0 && <li className="dropdown">
                                                        <a className="nav-link-style"><i className="fa fa-bars more-menus" aria-hidden="true"></i></a>
                                                        <ul className="dropdown-content">
                                                            {websiteDetails && websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                                                return (
                                                                    <li key={i}><a onClick={() => redirectTo(item)}>{item.menu_name}</a></li>

                                                                )
                                                            })}
                                                        </ul>
                                                    </li>}
                                                    


                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* //End Navbar  */}

                            </div>
                        </div>
                    </div>
                </header>
                {/* //End Sticky Navbar  */}

            </div>

        </div>
    )
}

export default Header