import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

function PromotionalBannerSection() {
    const [companyDetails] = useContext(CompanyContext);
    const [promoBannerSectionData, setPromotionalBannerSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const promoBannerSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-promotional-banner-section-data/' + company_id + '/' + template_id
                    );
                    const result = await promoBannerSection.json();

                    setPromotionalBannerSectionData({ "promotional_banner": result && result.promotional_banner ,"default_value":result && result.default_value});
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';

    const [modal, setModal] = useState(true);
    const customStyles = {
        content: {
            top: '57%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minwidth: "360px",
            width: '40%',
            height: "auto",
            padding: "1%"
        }
    };
    Modal.setAppElement('#root1')
    return (

        <div>
            {promoBannerSectionData && promoBannerSectionData.default_value && promoBannerSectionData.default_value==='promotional'&&<Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    <i className="fa fa-close pull-right" onClick={() => setModal(false)}></i>
                    {promoBannerSectionData && promoBannerSectionData.promotional_banner &&
                        <section className="activities_title ">
                            <div className="container notification">
                                <div className="resort_title">
                                    <img src={config.wbImagesEndpoint + '/' + promoBannerSectionData.promotional_banner.image} alt={promoBannerSectionData.promotional_banner.image_alt} />
                                </div>
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: promoBannerSectionData.promotional_banner.description }} style={{ fontFamily: websitefont }}></p>
                                </div>
                            </div>
                        </section>
                    }
                </div>

            </Modal>}
        </div>

    )
}

export default PromotionalBannerSection;